import {ChatInterfaceInfo} from "@/store/apiPayloadsFiles/payloadsInfo";
import {expertAIChat, individualAIChat} from "@/store/apiroutes/AIChatBotRoutes";

async function individualAIChatConversation(payload: {chatInterfaceInfo: ChatInterfaceInfo}) {
    const chatConversation = individualAIChat(payload.chatInterfaceInfo);
    try {
        const requestOptions = {
            method: chatConversation.method,
            headers: chatConversation.options.headers,
            body: JSON.stringify(chatConversation.payload),
            redirect: "follow"
        }
        // @ts-ignore
        const response = await fetch(chatConversation.url, requestOptions)
            .then((response) => response.json())
            .then((result) => result)
            .catch((error) => console.error(error));
        return response;
    } catch (error) {
        console.log('error', error);
        throw error;
    }
}

async function expertAIChatConversation(payload: {chatInterfaceInfo: ChatInterfaceInfo}) {
    const chatConversation = expertAIChat(payload.chatInterfaceInfo);
    try {
        // @ts-ignore
        const requestOptions = {
            method: chatConversation.method,
            headers: chatConversation.options.headers,
            body: JSON.stringify(chatConversation.payload),
            redirect: "follow"
        }

        // @ts-ignore
        const response = await fetch(chatConversation.url, requestOptions);
        if (!response.ok) {
            throw await response.json();
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
}

export default {
    individualAIChatConversation,
    expertAIChatConversation
}